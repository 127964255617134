/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './UserProfile.module.scss';

import { Loader } from '../../components/common/Loader';
import { Profile, Beneficiary } from '../../components/icons';
import zIcon from '../../assets/img/cardIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AccountType } from '../../constants/common';
import { getAccountSummary } from '../../store/accountSummarySlice';
import IconNavLink from '../../components/common/IconNavLink';
import { ICON_NAV_LINK_TYPES } from '../../components/common/IconNavLink/IconNavLink.constants';

export const UserProfile = () => {
	const [loading, setLoading] = useState(true);
	const [isOnlyDFSAccount, setIsOnlyDFSAccount] = useState<boolean>(true);

	const dispatch = useDispatch<any>();
	const summaryData = useSelector((state: any) => state.summaryData);
	const isHSAAccountHolder = summaryData?.data?.summary?.filter(
		(account: any) => account.type == AccountType.HSA && account.status == 'active'
	).length;

	// useEffect(() => {
	// 	(window as any).gtag('event', 'page_view', {
	// 		app_name: 'Zenda-web',
	// 		page_title: 'User_Profile',
	// 		// screen_name: 'Dashboard',
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 	});
	// }, []);

	useEffect(() => {
		if (!summaryData.loading) {
			setLoading(false);
			const dfsaAccount = summaryData.data?.summary.find(
				(account: { type: string }) => account.type == AccountType.DCFSA
			);
			if (dfsaAccount && dfsaAccount.status == 'active' && summaryData.data?.summary.length == 1) {
				setIsOnlyDFSAccount(true);
			} else {
				setIsOnlyDFSAccount(false);
			}
		} else {
			setLoading(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summaryData]);

	useEffect(() => {
		dispatch(getAccountSummary());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<div className={`d-flex align-items-center ${classes['text-white']} mt-128`}>
				<Profile color="#fff" width={36} height={36} />
				<p className={`text-h3-325 d-flex align-items-center px-16`}>User Profile</p>
			</div>

			{!loading && (
				<div className="d-flex flex-column mt-32">
					{!isOnlyDFSAccount && (
						<IconNavLink
							type={ICON_NAV_LINK_TYPES.LINK}
							href="/apps/mycards"
							leftIcon={<img src={zIcon} width={36} height={36} className="mr-8" />}
							title="My Benefits Card"
							isFirst
							wrapperClasses={`${classes['zenda-benefit-card']} ${classes['zenda-blue-card']}`}
							arrowColor="white"
						/>
					)}
					<IconNavLink
						type={ICON_NAV_LINK_TYPES.LINK}
						href="/apps/personal"
						leftIcon={<Profile color={'#111827'} width={32} height={32} />}
						title="Profile Information"
						mainSubtitle="Update your home address, login email, and phone number."
						wrapperClasses={classes['zenda-benefit-card']}
					/>
					{isHSAAccountHolder > 0 && (
						<IconNavLink
							type={ICON_NAV_LINK_TYPES.LINK}
							href="/apps/beneficiary/list"
							leftIcon={<Beneficiary color={'#111827'} width={32} height={32} />}
							isLast
							title="Manage Beneficiaries"
							mainSubtitle="Manage Beneficiaries for your HSA."
							wrapperClasses={classes['zenda-benefit-card']}
						/>
					)}
				</div>
			)}

			{loading && <Loader />}
		</React.Fragment>
	);
};
